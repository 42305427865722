import React from 'react';
import Box3 from './Box3'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// 파란색 텍스트 파싱 함수 + 기본 텍스트 파싱 함수
const parsingBasic = (content) => {
    return content.split('\n').map((line, index) => {
        // 먼저 $로 구분된 부분을 처리
        const dollarParts = line.split('$').map((part, idx) => (
            idx % 2 === 1 ? 
                <>
                    <span key={'span'+idx} style={{color: '#004777', marginBottom:'10px', fontSize:'20pt'}}>{part}</span>
                </> 
                : part
        ));

        // 이제 *로 구분된 부분을 처리
        const finalParts = dollarParts.map((segment, i) => {
            // segment가 string일 때만 *로 구분하여 밑줄 처리
            if (typeof segment === 'string') {
                const starParts = segment.split('*');
                return starParts.map((part, idx) =>
                    idx % 2 === 1 ? (
                        <div key={'diqqq'+idx} className='flex flex-row'>
                            <b style={{fontSize:'20pt', marginRight:'10pt', marginLeft:'10pt'}}> · </b>                    
                            <u style={{fontSize:'20pt', textUnderlineOffset:'5px'}}key={'u'+idx}>{part} <br /></u>
                        </div>
                    ) : part
                );
            }
            return segment;
        });

        return (
            <Typography key={index} sx={{fontSize:'20pt'}}>
                {finalParts.flat()} {/* flat을 사용하여 중첩된 배열을 평평하게 만듦 */}
                {/* 마지막엔 br 추가 안해 */}
                {index === content.split('\n').length - 1 ? null : <br />}
            </Typography>
        );
    });
};

// quiz + input
const quizInput = (quizlist, answer, handleInputChange, download) => {
    if(quizlist[0] === '') return (
        download ? 
            <div style={{border:'1px solid #000', padding:'10px'}}>
                <Typography sx={{fontSize:'20pt', mb:1}}>
                    {answer && answer[0] ? parsingBasic(answer[0]) : null}
                </Typography>
            </div>
         : 
        <TextField
            value={answer[0] || ''} 
            onChange={(e) => handleInputChange(0, e)} 
            multiline
            fullWidth
            variant="outlined"
            minRows={1} // 최소 줄 수 설정
            maxRows={10} // 최대 줄 수 설정 (원하는 만큼 설정 가능)
            InputProps={{
                style:{ border: '1px solid #000', width: '100%', fontSize:'20pt' }
            }}
        />
    );
    return quizlist.map((quiz, index) => {
        return (
            <Typography key={index} style={{ display: 'flex', alignItems: 'center', marginTop:'10px', fontSize:'20pt' }}>
                {quiz}
                <input 
                    type="text" 
                    style={{ flex: '1', border: '1px solid #000', marginLeft: '10px' }}
                    value={answer[index]}
                    onChange={(e) => handleInputChange(index, e)}
                />
            </Typography>
        );
    });
}

const parsecontent = (subtitle, subcontent, content, quiz, answer, handleInputChange, download) => {
    return content.split('\n').map((line, index) => {
        if(line.includes('!@#')) {
            return <Box3 key={index} title={subtitle} content={parsingBasic(subcontent)}/>;
        } else if(line.includes('$%^')) {
            return <Box3 key={index} title='실습' content={quizInput(quiz, answer, handleInputChange, download)}/>;
        }
        return (
            <Typography key={index} sx={{fontSize:'20pt', mb:1}}>
                {line}
            </Typography>
        );
    });
}

// 파싱된 텍스트 컴포넌트
export default function Quiz9Module ({content, subtitle, subcontent, quiz, answer, handleInputChange, download}) {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {parsecontent(subtitle, subcontent, content.trim(), quiz, answer, handleInputChange, download)}
        </div>
    )
};