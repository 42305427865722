import React from 'react';
import Typography from '@mui/material/Typography';

import Letter2Background from '../../../assets/images/sofimage/letter2Background.png';

const parsecontent = (content) => {
  return content.split('\n').map((line, index) => {
    return (
      <Typography key={index} sx={{mb:1, fontSize:'20pt', mr:'auto', ml: 2}}>
        {line}
        <br />
      </Typography>
    );
  });
}

const ReadLetter2 = ({answer}) => {
  return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${Letter2Background})`,
          backgroundSize: '100% 100%',  // 이미지가 요소 크기에 정확히 맞춰집니다
          backgroundRepeat: 'no-repeat',
          padding: '40px',
          width: 'auto',  // width와 height를 이미지 크기에 맞춰 자동으로 설정
          height: 'auto',
          margin: '20px auto',
          fontFamily: 'cursive',
        }}
      >
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>
        <Typography sx={{fontSize:'20pt'}}>
            사랑하는 {answer && answer[0] ? answer[0] : '     '} 에게
        </Typography>
        </div>

        <Typography style={{ marginBottom: '20px', width: '100%', minHeight:'300px', fontSize:'20pt'}}>
          {answer && answer[1] ? parsecontent(answer[1]) : null}
        </Typography>


        <Typography style={{ textAlign: 'right', width: '100%', fontSize:'20pt', marginRight:'230px' }}>
            너의 영원한 친구로부터
        </Typography>
    </div>
  );
};

export default ReadLetter2;
