import React, { useState, useEffect } from "react";
import Box3 from "./Box3";
import { Typography, Button, Tooltip, IconButton } from "@mui/material";
import plusImage from '../../../assets/images/ui/7. add.png';
import minusImage from '../../../assets/images/ui/8. minus.png';

const parseQuiz = (text, answer, handleInputChange, currentIndex) => {
    if(text.includes('**')) {
        return text.split('**').reduce((acc, part, index) => {
            if (index === 0) {
                return [part];
            }
            return [...acc, <input key={index} style={{ flex: '1', backgroundColor: '', border: '1px solid #000', width:'191px' }}  type="text" value={answer} onChange={(e)=>handleInputChange(currentIndex, e)} />, part];
        }, []);
    }    
};

export default function QuizBox3({ title, content, quizlist, answer, setAnswer, handleInputChange }) {
    const [inputs, setInputs] = useState([]);

    const addInput = () => {
        setInputs([...inputs, {}]);
        setAnswer([...answer, '']);
        console.log(answer)
    };

    useEffect(() => {
        setInputs(answer.slice(quizlist.length));
    }, [answer, quizlist.length]);

    const removeInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);

        const newAnswer = [...answer];
        newAnswer.splice(quizlist.length + index, 1);
        setAnswer(newAnswer);

        console.log(answer)
    };

    const combinedContent = [
        <Typography key="main-title" sx={{fontSize:'20pt', mb:2}}>당신이 스스로에 대해 했던 생각은 무엇인가요?</Typography>,
        <Typography key="main-content" sx={{fontSize:'20pt'}}>{content}</Typography>,
        ...quizlist.map((q, index) => {
            const currentIndex = index;
            return (
            <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{fontSize:'20pt', mb:2}}>{parseQuiz(q, answer[currentIndex], handleInputChange, currentIndex)}</Typography>
            </div>
        )}),
        ...inputs.map((_, index) => {
            const currentIndex = quizlist.length + index;
            return (
                <div key={currentIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{fontSize:'20pt', mb:2}}>{parseQuiz(quizlist[quizlist.length - 1], answer[currentIndex], handleInputChange, currentIndex)}</Typography>
                    <IconButton
                        onClick={() => removeInput(index)}
                        sx={{
                            borderRadius: '100px',
                        }}
                    >
                        <img src={minusImage} width={24} style={{filter:'invert(27%) sepia(85%) saturate(6919%) hue-rotate(354deg) brightness(99%) contrast(127%)'}} />
                        <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>삭제</Typography>
                    </IconButton>
                </div>
            );
        })
    ];

    return (
        <>
            <Box3 title={title} content={combinedContent}>
                <IconButton onClick={addInput}>
                    <img src={plusImage} width={24} style={{filter:'invert(45%) sepia(82%) saturate(1857%) hue-rotate(161deg) brightness(94%) contrast(101%)'}} />
                    <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>추가</Typography>
                </IconButton>
            </Box3>
            
        </>
    );
}
