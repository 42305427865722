import React, { useState, useEffect, useContext } from 'react';
import SOFMoudle from '../SOFModule';

import {
    fetchGetAnswer,
    fetchGetAllO1,
    fetchCheckFinishSOF,
    fetchGetAllO2,
    fetchSubmitAnswer,
    fetchFinishSOF,
    fetchCheckMaxStep,
} from '../../../services/SOFServices';
import { UserContext } from '../../../core/user';
import VerticalNavbar from '../../../components/navbar/SOFNavbar';

const O = () => {
    const { userState } = useContext(UserContext);

    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [canGoToMenu, setCanGoToMenu] = useState([1, 1]);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    const handleFetchNavbar = () => {
        fetchCheckMaxStep(userState.uid, `${type}1`).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data === -1) {
                        fetchCheckMaxStep(userState.uid, `${type}2`).then(
                            (response) => {
                                if (response.ok) {
                                    response.json().then((data) => {
                                        if (data === -1) {
                                            setCanGoToMenu([3, 12]);
                                        } else {
                                            setCanGoToMenu([2, data]);
                                        }
                                    });
                                }
                            },
                        );
                    } else {
                        setCanGoToMenu([1, data]);
                    }
                });
            }
        });
    };

    const handleError = async () => {
        const check_o2_finish = await fetchCheckFinishSOF('o2', userState.uid);
        
        if (check_o2_finish.ok) {
            const data = await check_o2_finish.json();
            if(data) {
                fetchFinishSOF('o1', userState.uid).then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            console.log(data);
                        });
                    }
                });
            }
        }
    }

    const type = 'o';
    const [step, setStep] = useState(parseInt(userState.sof, 10));
    const [loading, setLoading] = useState(true);
    const [o, setO] = useState([]);
    const [finishO1, setFinishO1] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [isO1, setIsO1] = useState(true);

    useEffect(() => {
        if (!userState || !userState.sof) {
            return; // userState가 준비되지 않았으면 로직을 실행하지 않음
        }

        handleFetchNavbar();

        const checkFinishAndFetchData = async () => {
            try {
                const response = await fetchCheckFinishSOF('o1', userState.uid);
                if (response.ok) {
                    const data = await response.json();
                    setFinishO1(data);

                    if (data) {
                        const o2Response = await fetchGetAllO2();
                        if (o2Response.ok) {
                            const o2Data = await o2Response.json();
                            setO(o2Data);
                            setLoading(false);
                            setIsO1(false);
                        }
                    } else {
                        const o1Response = await fetchGetAllO1();
                        if (o1Response.ok) {
                            const o1Data = await o1Response.json();
                            setO(o1Data);
                            setLoading(false);
                            setIsO1(true);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        checkFinishAndFetchData();
    }, [userState.uid]);

    useEffect(() => {
        const fetchAnswer = async () => {
            if (o.length > 0 && step < o.length) {
                try {
                    const currentStep = o[step];
                    const response2 = await fetchGetAnswer(
                        userState.uid,
                        isO1 ? 'o1' : 'o2',
                        isO1 ? currentStep.o1id : currentStep.o2id,
                    );
                    if (response2.ok) {
                        const data = await response2.json();
                        setAnswer(data.answer);
                    }
                } catch (error) {
                    const currentStep = o[step];
                    if (currentStep.type === 'letter2') {
                        setAnswer(new Array(2).fill(''));
                    } else if (currentStep.type === 'feedback') {
                        setAnswer(new Array(3).fill(''));
                    } else if (currentStep.quiz.length > 0) {
                        setAnswer(
                            currentStep.quiz[0] === ''
                                ? []
                                : new Array(currentStep.quiz.length).fill(''),
                        );
                    }
                }
            }
        };

        fetchAnswer();

        handleError();
    }, [o, step, userState.uid, isO1]);

    const handleSubmitAnswer = () => {
        const answerinfo = isO1
            ? { uid: userState.uid, o1id: o[step].o1id, answer }
            : { uid: userState.uid, o2id: o[step].o2id, answer };

        fetchSubmitAnswer(answerinfo, userState.uid, isO1 ? 'o1' : 'o2');

        if (o[step].type === 'letter2') {
            const answerinfo = {
                uid: userState.uid,
                o2id: o[step].o2id + 1,
                answer,
            };
            fetchSubmitAnswer(answerinfo, userState.uid, 'o2');
        }
        handleFetchNavbar();
    };

    const finishSOF = () => {
        fetchFinishSOF(isO1 ? 'o1' : 'o2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                });
            }
        });
    };

    return (
        <div className="flex flex-grow relative">
            <aside
                className={`transition-width duration-300`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    zIndex: 10,
                    width: isNavExpanded ? '250px' : '80px', // 예시로 width 설정, 필요에 맞게 변경
                }}
            >
                <VerticalNavbar
                    curStep={step}
                    isExpanded={isNavExpanded}
                    toggleNavbar={toggleNavbar}
                    setStep={setStep}
                    type={type}
                    isFirst={isO1}
                    setIsFirst={setIsO1}
                    setSOF={setO}
                    userState={userState}
                    canGoToMenu={canGoToMenu}
                />
            </aside>
            <main className="flex-grow p-2 overfslow-auto">
                <div className="flex flex-col w-full h-full space-y-2">
                    <div className="flex flex-col justify-start w-full h-full flex-grow">
                        {o.length > 0 ? (
                            <SOFMoudle
                                step={step}
                                sof={o}
                                setStep={setStep}
                                doc={o[step]}
                                setSOF={setO}
                                answer={answer}
                                setAnswer={setAnswer}
                                finishSOF={finishSOF}
                                setIsFirst={setIsO1}
                                isFirst={isO1}
                                fetchGetAllSecond={fetchGetAllO2}
                                handleSubmitAnswer={handleSubmitAnswer}
                                type={type}
                            />
                        ) : null}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default O;
