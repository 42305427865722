import React, { useState } from "react";
import { IconButton, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Typography, Icon } from "@mui/material";
import Box3 from './Box3';
import plusImage from '../../../assets/images/ui/7. add.png';
import minusImage from '../../../assets/images/ui/8. minus.png';

export default function QuizBox4({ title, quizlist, answer, setAnswer, handleInputChangeBasic, download }) {
    const [rows, setRows] = useState(['', '', '', '']);
    const firstrow = [quizlist[0], quizlist[1]];

    const handleAddRow = () => {
        setRows([...rows, '', '']);
        setAnswer([...answer, '', '']);
    };

    const handleDeleteRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index * 2, 2);
        setRows(newRows);

        const newAnswer = [...answer];
        newAnswer.splice(index * 2, 2);
        setAnswer(newAnswer);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = [...rows];
        const actualIndex = index * 2 + (field === 'activity' ? 1 : 0);
        newRows[actualIndex] = value;
        setRows(newRows);

        const event = { target: { value } };
        handleInputChangeBasic(actualIndex, event);
        console.log(answer);
    };

    const quizTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <Typography sx={{ fontSize: '20pt' }}>
                            날짜
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography sx={{ fontSize: '20pt' }}>
                            활동
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center" sx={{ borderTop: '1.5px solid #000', borderRight: '1.5px solid #000' }}>
                        <Typography align="center" sx={{ fontSize: '20pt' }}>{firstrow[0]}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{  borderTop: '1.5px solid #000' }}>
                        <Typography align="center" sx={{fontSize: '20pt'}}>{firstrow[1]}</Typography>
                    </TableCell>
                </TableRow>
                {answer.reduce((acc, _, index) => {
                    if (index % 2 === 0) {
                        acc.push(
                            <TableRow key={index / 2} sx={{ position: 'relative' }}>
                                <TableCell align="center" sx={{ borderTop: '1.5px solid #000', borderRight: '1.5px solid #000' }}>
                                    <TextField
                                        value={answer[index]}
                                        onChange={(e) => handleInputChange(index / 2, 'date', e.target.value)}
                                        style={{ textAlign: 'center', fontSize:'20pt' }}
                                        size="small"
                                        inputProps={{ style: { textAlign: 'center', fontSize:'20pt' } }}
                                    />
                                </TableCell>
                                <TableCell align="center" sx={{ borderTop: '1.5px solid #000', position: 'relative' }}>
                                    <TextField
                                        value={answer[index + 1]}
                                        onChange={(e) => handleInputChange(index / 2, 'activity', e.target.value)}
                                        style={{ textAlign: 'center' }}
                                        size="small"
                                        inputProps={{ style: { textAlign: 'center', fontSize:'20pt' } }}
                                    />
                                    {!download && index !== 0 && index !== 2 &&
                                        <IconButton
                                            onClick={() => handleDeleteRow(index / 2)}
                                            sx={{
                                                position: 'absolute',
                                                bottom: 8,
                                                right: 8,
                                                borderRadius: '100px',
                                            }}
                                        >
                                            <img src={minusImage} width={24} style={{filter:'invert(27%) sepia(85%) saturate(6919%) hue-rotate(354deg) brightness(99%) contrast(127%)'}} />
                                            <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>삭제</Typography>
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    }
                    return acc;
                }, [])}
                {!download &&
                    <TableRow>
                        <TableCell colSpan={3} align="left">
                            <IconButton onClick={handleAddRow}>
                                <img src={plusImage} width={24} style={{filter:'invert(45%) sepia(82%) saturate(1857%) hue-rotate(161deg) brightness(94%) contrast(101%)'}} />
                                <Typography sx={{color:'#000', fontSize:'16pt', ml:1}}>추가</Typography>        
                            </IconButton>
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    );

    return (
        <>
            <Box3 title={title} content={quizTable()} />
        </>
    );
}
