import React, { useState, useEffect, useContext } from 'react';
import SOFMoudle from '../SOFModule';

import {
    fetchGetAnswer,
    fetchGetAllF1,
    fetchCheckFinishSOF,
    fetchGetAllF2,
    fetchSubmitAnswer,
    fetchFinishSOF,
    fetchCheckMaxStep,
} from '../../../services/SOFServices';
import { UserContext } from '../../../core/user';
import VerticalNavbar from '../../../components/navbar/SOFNavbar';

const O = () => {
    const { userState } = useContext(UserContext);

    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [canGoToMenu, setCanGoToMenu] = useState([1, 1]);

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    const handleFetchNavbar = () => {
        fetchCheckMaxStep(userState.uid, `${type}1`).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data === -1) {
                        fetchCheckMaxStep(userState.uid, `${type}2`).then(
                            (response) => {
                                if (response.ok) {
                                    response.json().then((data) => {
                                        if (data === -1) {
                                            setCanGoToMenu([3, 12]);
                                        } else {
                                            setCanGoToMenu([2, data]);
                                        }
                                    });
                                }
                            },
                        );
                    } else {
                        setCanGoToMenu([1, data]);
                    }
                });
            }
        });
    };

    const handleError = async () => {
        const check_f2_finish = await fetchCheckFinishSOF('f2', userState.uid);
        
        if (check_f2_finish.ok) {
            const data = await check_f2_finish.json();
            if(data) {
                fetchFinishSOF('f1', userState.uid).then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            console.log(data);
                        });
                    }
                });
            }
        }
    }

    const type = 'f';
    const [step, setStep] = useState(parseInt(userState.sof, 10));
    const [loading, setLoading] = useState(true);
    const [f, setF] = useState([]);
    const [finishF1, setFinishF1] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [isF1, setIsF1] = useState(true);

    useEffect(() => {
        if (!userState || !userState.sof) {
            return; // userState가 준비되지 않았으면 로직을 실행하지 않음
        }

        handleFetchNavbar();

        const checkFinishAndFetchData = async () => {
            try {
                const response = await fetchCheckFinishSOF('f1', userState.uid);
                if (response.ok) {
                    const data = await response.json();
                    setFinishF1(data);

                    if (data) {
                        const f2Response = await fetchGetAllF2();
                        if (f2Response.ok) {
                            const f2Data = await f2Response.json();
                            setF(f2Data);
                            setLoading(false);
                            setIsF1(false);
                        }
                    } else {
                        const f1Response = await fetchGetAllF1();
                        if (f1Response.ok) {
                            const f1Data = await f1Response.json();
                            setF(f1Data);
                            setLoading(false);
                            setIsF1(true);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        checkFinishAndFetchData();
    }, [userState.uid]);

    useEffect(() => {
        const fetchAnswer = async () => {
            if (f.length > 0 && step < f.length) {
                try {
                    const currentStep = f[step];
                    const response2 = await fetchGetAnswer(
                        userState.uid,
                        isF1 ? 'f1' : 'f2',
                        isF1 ? currentStep.f1id : currentStep.f2id,
                    );
                    if (response2.ok) {
                        const data = await response2.json();
                        setAnswer(data.answer);
                    }
                } catch (error) {
                    const currentStep = f[step];
                    if (currentStep.type === 'quiz7') {
                        setAnswer([]);
                    } else if (currentStep.type === 'feedback') {
                        setAnswer(new Array(3).fill(''));
                    } else if (currentStep.type === 'quiz9') {
                        setAnswer(new Array(1).fill(''));
                    } else if (currentStep.quiz.length > 0) {
                        setAnswer(
                            currentStep.quiz[0] === ''
                                ? []
                                : new Array(currentStep.quiz.length).fill(''),
                        );
                    } else {
                        setAnswer(['']);
                    }
                }
            }
        };

        fetchAnswer();

        handleError();
    }, [f, step, userState.uid, isF1]); 

    const handleSubmitAnswer = () => {
        const answerinfo = isF1
            ? { uid: userState.uid, f1id: f[step].f1id, answer }
            : { uid: userState.uid, f2id: f[step].f2id, answer };

        fetchSubmitAnswer(answerinfo, userState.uid, isF1 ? 'f1' : 'f2');

        if (f[step].type === 'letter2') {
            const answerinfo = {
                uid: userState.uid,
                f2id: f[step].f2id + 1,
                answer,
            };
            fetchSubmitAnswer(answerinfo, userState.uid, 'f2');
        }
        handleFetchNavbar();
    };

    const finishSOF = () => {
        fetchFinishSOF(isF1 ? 'f1' : 'f2', userState.uid).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log(data);
                });
            }
        });
    };

    return (
        <div className="flex flex-grow relative">
            <aside
                className={`transition-width duration-300`}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    zIndex: 10,
                    width: isNavExpanded ? '250px' : '80px', // 예시로 width 설정, 필요에 맞게 변경
                }}
            >
                <VerticalNavbar
                    curStep={step}
                    isExpanded={isNavExpanded}
                    toggleNavbar={toggleNavbar}
                    setStep={setStep}
                    type={type}
                    isFirst={isF1}
                    setIsFirst={setIsF1}
                    setSOF={setF}
                    userState={userState}
                    canGoToMenu={canGoToMenu}
                />
            </aside>
            <main className="flex-grow p-2 overfslow-auto">
                <div className="flex flex-col w-full h-full space-y-2">
                    <div className="flex flex-col justify-start w-full h-full flex-grow">
                        {f.length > 0 ? (
                            <SOFMoudle
                                step={step}
                                sof={f}
                                setStep={setStep}
                                doc={f[step]}
                                setSOF={setF}
                                answer={answer}
                                setAnswer={setAnswer}
                                finishSOF={finishSOF}
                                setIsFirst={setIsF1}
                                isFirst={isF1}
                                fetchGetAllSecond={fetchGetAllF2}
                                handleSubmitAnswer={handleSubmitAnswer}
                                type={type}
                            />
                        ) : null}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default O;
